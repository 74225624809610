import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/Seo';
import RichTextPost from '../components/RichTextPost';
import SectionContainer from '../components/SectionContainer';
import ContentWrapper from '../components/ContentWrapper';
import RichText from '../components/RichText';
import LayoutTemplate from './LayoutTemplate';

export default function PrivacyPolicyTemplate({ data, location }) {
  return (
    <LayoutTemplate showNav>
      <Seo title={data.privacyPolicy.title} pathname={location.pathname} />
      <SectionContainer backgroundColor="white">
        <ContentWrapper>
          <h1>Privacy Policy: {data.privacyPolicy.title}</h1>
          <hr />
          {data.privacyPolicy.content ? (
            <RichText content={data.privacyPolicy.content} />
          ) : null}
        </ContentWrapper>
      </SectionContainer>
    </LayoutTemplate>
  );
}

export const query = graphql`
  query getPrivacyPolicy($slug: String!) {
    privacyPolicy: contentfulPrivacyPolicy(slug: { eq: $slug }) {
      id
      title
      content {
        raw
      }
    }
  }
`;
